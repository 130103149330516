<template>
    <div class="draw">
        <div class="draw-logo">
            <img src="../../assets/img/research-draw/logo.png"/>
        </div>
        <div class="draw-container">
            <div class="title">
                <img src="../../assets/img/research-draw/title.png">
            </div>
            <div class="draw-md">
                <div class="draw-md-title">
                    <img src="../../assets/img/research-draw/md.png">
                </div>
                <div class="draw-md-list">
                    <div class="draw-md-item">
                        <h3>奖品1：精品保温杯1个 共20人</h3>
                        <div class="draw-md-item-two">
                            <span v-for="item in list1">{{ item|filtersPhone }}</span>
                        </div>
                    </div>
                    <div class="draw-md-item">
                        <h3>奖品2：定制U盘1个 共10人</h3>
                        <div class="draw-md-item-one">
                            <span v-for="item in list2">{{ item|filtersPhone }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="draw-btn-content">
                <div class="draw-btn" @click="startDraw">{{ btnTxt }}</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                btnTxt: '开始抽奖',
                list1: [
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                ],
                list2: [
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                ],
                list: [
                    '13891927168', '13891867168', '13891967168', '13892888676', '13891888550', '13991129399', '13991313394', '13991114194',
                    '13991914349', '13991141943', '13991141949', '13991349133', '13991943119', '13991949193', '13991144341', '13991993441',
                    '13991314393', '13991931439', '13991341434', '13991394339', '13991919341', '13991344341', '13991344394', '13991341994',
                    '13669277890', '15091677890', '13892837168', '13991279168', '15829696966', '13892816899', '13991114493', '13991114143',
                    '13991113941', '18291451234', '18291491234', '18291941234', '15249292345', '15002942345', '15710482345', '15877343456',
                    '13891927168', '13891867168', '13891967168', '13892888676', '13891888550', '13991129399', '13991313394', '13991114194',
                    '13991914349', '13991141943', '13991141949', '13991349133', '13991943119', '13991949193', '13991144341', '13991993441',
                    '13991314393', '13991931439', '13991341434', '13991394339', '13991919341', '13991344341', '13991344394', '13991341994',
                    '13669277890', '15091677890', '13892837168', '13991279168', '15829696966', '13892816899', '13991114493', '13991114143',
                    '13991113941', '18291451234', '18291491234', '18291941234', '15249292345', '15002942345', '15710482345', '15877343456',
                    '13891927168', '13891867168', '13891967168', '13892888676', '13891888550', '13991129399', '13991313394', '13991114194',
                    '13991914349', '13991141943', '13991141949', '13991349133', '13991943119', '13991949193', '13991144341', '13991993441',
                    '13991314393', '13991931439', '13991341434', '13991394339', '13991919341', '13991344341', '13991344394', '13991341994',
                    '13669277890', '15091677890', '13892837168', '13991279168', '15829696966', '13892816899', '13991114493', '13991114143',
                    '13991113941', '18291451234', '18291491234', '18291941234', '15249292345', '15002942345', '15710482345', '15877343456',
                ],
                interval: null,
                info: {}
            }
        },
        filters: {
            filtersPhone(val) {
                return val.substring(0,3) + '****' + val.substring(7,11)
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                this.$axios.post(this.apiUrl.researchLuckDrawNumber).then(res => {
                    this.info = res.data
                })
            },
            startDraw() {
                if(!this.isDraw) {
                    /* 开始抽奖 */
                    this.isDraw = true;
                    this.btnTxt = '结束抽奖';

                    this.interval = setInterval(()=> {
                        for(let i = 0;i<this.list1.length;i++) {
                            this.$set(this.list1, i, this.list[Math.floor((Math.random()*this.list.length))])
                        }
                        for(let i = 0;i<this.list2.length;i++) {
                            this.$set(this.list2, i, this.list[Math.floor((Math.random()*this.list.length))])
                        }
                    }, 100)
                }else {
                    /* 结束抽奖 */
                    this.$axios.post(this.apiUrl.researchLuckDraw, this.info).then(res => {
                        if(res.code == 1) {
                            this.$message.success(res.msg);
                            this.isDraw = false;
                            this.btnTxt = '开始抽奖';
                            clearInterval(this.interval);
                            let arr1 = []
                            let arr2 = []
                            res.data.map(item => {
                                if(item.type == 1) {
                                    arr1.push(item.phone)
                                }
                                if(item.type == 2) {
                                    arr2.push(item.phone)
                                }
                            })

                            this.list1 = arr1;
                            this.list2 = arr2;
                        }else {
                            this.isDraw = false;
                            clearInterval(this.interval);
                            this.btnTxt = '开始抽奖';
                            this.list1 = [
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                            ]
                            this.list2 = [
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********'
                            ]
                            this.$message.info(res.msg)
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .draw{
        background: url("../../assets/img/research-draw/draw_bg.png") no-repeat;
        min-height: 100vh;
        background-size: 100% 100%;
        position: relative;
        .draw-logo{
            position: absolute;
            left: 43px;
            top: 31px;
            img{
                height: 33px;
            }
        }
        .draw-container{
            width: 1100px;
            margin: 0 auto;
            padding-top: 48px;
            .title{
                text-align: center;
                img{
                    height: 209px;
                }
            }
            .draw-md{
                background: rgba(255, 255, 255, 0.17);
                opacity: 1;
                border: 1px solid;
                border-image: linear-gradient(180deg, rgba(255, 199, 158, .5), rgba(255, 199, 158, 0)) 1 1;
                margin-top: 46px;
                position: relative;
                .draw-md-title{
                    position: absolute;
                    left: 50%;
                    top: -35px;
                    margin-left: -173px;
                    img{
                        width: 346px;
                    }
                }
                .draw-md-list{
                    text-align: center;
                    padding-bottom: 28px;
                    .draw-md-item{
                        width: 356px;
                        display: inline-block;
                        margin: 0 40px;
                        vertical-align: top;
                        h3{
                            font-size: 24px;
                            font-family: PingFang SC-Bold, PingFang SC;
                            font-weight: bold;
                            color: #FFF383;
                            margin-top: 51px;
                            margin-bottom: 12px;
                        }
                        .draw-md-item-two,.draw-md-item-one{
                            width: 356px;
                            height: 455px;
                            background: linear-gradient(180deg, #150A33 0%, rgba(81, 79, 157, 0) 100%);
                            border-radius: 0px 0px 0px 0px;
                            opacity: 1;
                            border: 1px solid;
                            border-image: linear-gradient(180deg, rgba(255, 235, 58, 0.61), rgba(255, 199, 158, 0)) 1 1;
                            padding: 10px 0;
                        }
                        .draw-md-item-two{
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            span{
                                flex: 1;
                                min-width: 50%;
                                max-width: 50%;
                                width: 50%;
                                font-size: 20px;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                        .draw-md-item-one{
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            flex-direction: column;
                            span{
                                flex: 1;
                                font-size: 20px;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #FFFFFF;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }

    }
    .draw-btn-content{
        text-align: center;
        padding: 28px 0 95px;
        .draw-btn{
            display: inline-block;
            width: 254px;
            height: 82px;
            background: linear-gradient(360deg, #DA3511 0%, #F46133 100%);
            border-radius: 77px;
            opacity: 1;
            border: 1px solid #fff;
            overflow: hidden;
            text-align: center;
            line-height: 82px;
            font-size: 30px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
</style>
